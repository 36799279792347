@at-root {
  .page-classCat {
    --barPageheaderHight: 220px;
    @include media-breakpoint-up(sm) {
      --barPageheaderHight: 200px;
    }
  }
}

&-classCat {
  position: relative;
  color: var(--invert-text0);

  &-container {
    display: flex;
    justify-content: space-between;
    height: 100%;
    overflow: hidden;
    flex-wrap: wrap;
    padding-top: 32px;
    padding-bottom: 32px;
    @include media-breakpoint-up(sm) {
      padding-top: 20px;
      padding-bottom: 20px;
    }

    &-left {
      display: flex;
      justify-content: center;
      flex-direction: column;
    }
    &-right {
      display: flex;
      align-items: center;
    }
  }
  &-title {
    font-size: 24px;
    margin: 0;
    margin-bottom: 12px;
    font-weight: bold;
    @include media-breakpoint-up(sm) {
      margin-bottom: 16px;
    }
    @include media-breakpoint-up(md) {
      font-size: 36px;
    }
    @include media-breakpoint-up(xl) {
      font-size: 48px;
    }
  }
  &-info {
    @include reset-list;

    display: flex;
    opacity: 0.5;

    font-size: 12px;
    @include media-breakpoint-up(sm) {
      font-size: 20px;
    }

    @supports (gap: 12px) {
      column-gap: 12px;
      > li:not(:last-child) {
        padding-right: 12px;
      }
    }
    @supports not (gap: 12px) {
      > li:not(:last-child) {
        margin-right: 12px;
      }
    }

    > li:not(:last-child) {
      position: relative;
      &:after {
        content: '';
        display: block;
        position: absolute;
        background-color: var(--invert-text0);
        width: 1px;
        height: 1em;
        top: 50%;
        right: 0;
        transform: translate(100%, -50%);
      }
    }

    .value {
      margin-right: 0.25em;
    }
  }
  // class container right
  &-btn {
    color: var(--invert-text0);
    border-radius: 0;
    font-size: 16px;
    padding: 12px 26px;
    @include media-breakpoint-up(sm) {
      font-size: 20px;
    }
    @include media-breakpoint-up(lg) {
      font-size: 24px;
    }
    @include media-breakpoint-up(xl) {
      padding: 28px 54px;
    }
    &:hover {
      color: var(--invert-text0);
    }
    &.apply {
      background-color: var(--blue-ruin);
      font-weight: 600;
      @include media-breakpoint-down(sm) {
        font-weight: 500;
        padding-top: 7px;
        padding-bottom: 9px;
        font-size: 16px;
      }
      @include media-breakpoint-up(sm) {
        font-weight: 400;
      }
      @include media-breakpoint-up(lg) {
        font-weight: bold;
      }
    }
    &.review {
      display: none;
      font-weight: normal;
      border-left: 1px solid rgba(var(--RGB-invert-text0), 0.2);

      @include media-breakpoint-down(lg) {
        display: none !important;
      }
    }
  }
}

@mixin bar-class-small {
  & + main.class.cat {
    --barPageheaderHight: 80px;
    @include media-breakpoint-up(sm) {
      --barPageheaderHight: 56px;
    }
  }
  .bar-bg {
    @include media-breakpoint-up(sm) {
      display: none;
    }
  }
  .bar-classCat {
    --barPageheaderHight: 80px;
    @include media-breakpoint-up(sm) {
      --barPageheaderHight: 56px;
      background-color: var(--theatre-blue);
    }

    &-container {
      padding-top: 0;
      padding-bottom: 0;
      flex-wrap: nowrap;
      @include media-breakpoint-down(sm) {
        padding-right: 0;
      }
      &-left {
        margin-bottom: 0;
        padding-top: 14px;
        padding-bottom: 14px;
        @include media-breakpoint-down(sm) {
          padding-right: 16px;
        }
      }
    }
    &-title {
      margin-bottom: 0;
      font-size: 16px;
      font-weight: normal;
      @include media-breakpoint-up(sm) {
        font-size: 20px;
      }
      @include media-breakpoint-up(lg) {
        font-size: 24px;
        font-weight: bold;
      }
    }
    &-info {
      display: none;
    }
    &-btn {
      font-size: 13px;
      white-space: nowrap;
      padding: 10px 20px;
      font-weight: 500;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      @include media-breakpoint-up(sm) {
        font-size: 18px;
      }
      &.review {
        @include media-breakpoint-up(sm) {
          display: flex;
        }
      }
    }
  }
}
