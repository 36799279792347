&-noti {
  svg {
    fill: #615b5f;
  }
  button {
    .bell {
      opacity: 0;
      transition: opacity 0.3s;
    }
    &.on .bell {
      opacity: 1;
    }
  }
  .noti {
    &-submenu {
      padding: 0;
      height: 290px;
      overflow-y: scroll;

      &::-webkit-scrollbar {
        background-color: transparent;
        width: 6px;
        height: 6px;
      }

      &::-webkit-scrollbar-track {
        background-color: var(--zhen-zhu-bai-pearl);
      }

      &::-webkit-scrollbar-thumb {
        background-color: var(--super-silver);
      }
    }
    &-item {
      padding: 16px 24px;
      margin: 0;
      &:not(:last-child) {
        border-bottom: 1px solid var(--super-silver);
      }

      &-title {
        padding: 26px 24px 0;
        margin: 0;
        font-size: 16px;
        font-weight: 600;
        color: var(--black-out);
      }
    }
    &-header {
      font-size: 16px;
      color: var(--text1);
      margin-bottom: 8px;
    }
    &-content {
      color: var(--text3);
      a {
        color: var(--blue-ruin);
      }
      .history,
      b {
        color: var(--text0);
      }
      .history {
        margin-top: 4px;
        display: inline-block;
      }
    }
    &-close-btn {
      text-decoration: none;
      color: #666666;
      border: 1px solid var(--cerebral-grey);
      border-radius: 2px;
      white-space: nowrap;
      padding: 4px 15px 5px;
      font-size: 13px;
      font-weight: normal;
      background-color: white;
    }
    &-rao-btn {
      text-decoration: none;
      color: #666666;
      border: 1px solid var(--cerebral-grey);
      border-radius: 2px;
      white-space: nowrap;
      padding: 4px 15px 5px;
      font-size: 13px;
      font-weight: normal;
      background-color: white;
    }
    &-close-btn-dark {
      text-decoration: none;
      color: #666666;
      border: 1px solid var(--cerebral-grey);
      border-radius: 2px;
      white-space: nowrap;
      padding: 4px 15px 5px;
      font-size: 13px;
      font-weight: normal;
      background-color: #ebebeb;
    }

    &-content + .noti-close-btn {
      margin-top: 8px;
    }

    &-footer {
      border-top: 1px solid var(--super-silver);
      padding: 20px 24px;
      display: flex;
      gap: 4px;
    }
  }
}
