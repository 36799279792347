&-profile {
  align-items: center;
  display: flex;
  flex-direction: column;

  &-label {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 28px;
    color: var(--text2);
  }
  &-img {
    display: flex;
    align-items: center;
    justify-content: center;
    .imgWrap {
      border-radius: 999px;
      overflow: hidden;
      margin-bottom: 17px;
      @include media-breakpoint-up(sm) {
        margin-bottom: 32px;
      }
    }
  }
}
