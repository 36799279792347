$base: (
  blue-ruin: #006ddb,
  steam: #dddddd,
  blueberry-twist: #244f80,
  theatre-blue: #234a7a,
  black-out: #222222,
  super-silver: #eeeeee,
  cerebral-grey: #cccccc,
  dhusar-grey: #aaaaaa,
  paris: #90a4bd,
  ice-fishing: #ddeff6,
  common-feldspar: #858f96,
  simple-serenity: #c7d9e5,
  tin: #919191,
  zhen-zhu-bai-pearl: #f8f8f8,
  simply-blue: #adb9c8,
  cold-morning: #e5e5e5,
  azure: #00aced,
  blue-hijab: #d0f0fc,
  whiteout: #fbfbfb,
  caribbean-mist: #cadee9,
  bleached-silk: #f2f2f2,
);

:root {
  @include generate_colors($base);
}
