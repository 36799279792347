
@keyframes p20 {
  99% {}
  100% {
    --stroke-color: #c0fcff;
  }
}

@keyframes p30 {
  50% {
    --stroke-color: #c0fcff;
  }
  100% {
    --stroke-color: #97f5ff;
  }
}

@keyframes p40 {
  33% {
    --stroke-color: #c0fcff;
  }
  66% {
    --stroke-color: #97f5ff;
  }
  100% {
    --stroke-color: #71ebff;
  }
}

@keyframes p50 {
  25% {
    --stroke-color: #c0fcff;
  }
  50% {
    --stroke-color: #97f5ff;
  }
  75% {
    --stroke-color: #71ebff;
  }
  100% {
    --stroke-color: #2cd4ff;
  }
}

@keyframes p60 {
  20% {
    --stroke-color: #c0fcff;
  }
  40% {
    --stroke-color: #97f5ff;
  }
  60% {
    --stroke-color: #71ebff;
  }
  80% {
    --stroke-color: #2cd4ff;
  }
  100% {
    --stroke-color: #32c3ff;
  }
}

@keyframes p70 {
  16.6% {
    --stroke-color: #c0fcff;
  }
  33.2% {
    --stroke-color: #97f5ff;
  }
  49.8% {
    --stroke-color: #71ebff;
  }
  66.4% {
    --stroke-color: #2cd4ff;
  }
  83% {
    --stroke-color: #32c3ff;
  }
  100% {
    --stroke-color: #38b8ff;
  }
}

@keyframes p80 {
  14.2% {
    --stroke-color: #c0fcff;
  }
  28.4% {
    --stroke-color: #97f5ff;
  }
  42.6% {
    --stroke-color: #71ebff;
  }
  56.8% {
    --stroke-color: #2cd4ff;
  }
  71% {
    --stroke-color: #32c3ff;
  }
  85.2% {
    --stroke-color: #38b8ff;
  }
  100% {
    --stroke-color: #28ade8;
  }
}

@keyframes p90 {
  12.5% {
    --stroke-color: #c0fcff;
  }
  25% {
    --stroke-color: #97f5ff;
  }
  37.5% {
    --stroke-color: #71ebff;
  }
  50% {
    --stroke-color: #2cd4ff;
  }
  62.5% {
    --stroke-color: #32c3ff;
  }
  75% {
    --stroke-color: #38b8ff;
  }
  87.5% {
    --stroke-color: #28ade8;
  }
  100% {
    --stroke-color: #298cef;
  }
}

@keyframes p100 {
  11.1% {
    --stroke-color: #c0fcff;
  }
  22.2% {
    --stroke-color: #97f5ff;
  }
  33.3% {
    --stroke-color: #71ebff;
  }
  44.4% {
    --stroke-color: #2cd4ff;
  }
  55.5% {
    --stroke-color: #32c3ff;
  }
  66.6% {
    --stroke-color: #38b8ff;
  }
  77.7% {
    --stroke-color: #28ade8;
  }
  88.8% {
    --stroke-color: #298cef;
  }
  100% {
    --stroke-color: #006ddb;
  }
}
