&-review {
  padding: 32px 20px;
  color: var(--text3);
  border: 1px solid var(--steam);

  @include media-breakpoint-up(sm) {
    padding: 2.45em;
  }
  &-header {
    display: flex;
    align-items: center;
    margin-bottom: 24px;

    @include gap(16px);
  }
  &-thumbnail {
    border-radius: 999px;
    width: 68px;
    height: 68px;
    overflow: hidden;
    position: relative;
    img {
      width: 100%;
    }
    @supports (object-fit: cover) {
      img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        object-fit: cover;
        width: 100%;
        height: 100%;
      }
    }
  }
  &-name {
    color: var(--text1);
    font-size: 1.11111111em;
    font-weight: bold;
    margin-bottom: 4px;
  }
  &-date {
    color: var(--text4);
    font-size: 0.88888889em;
  }
  &-body {
    overflow: hidden;
    > *:last-child {
      margin-bottom: 0;
    }
    @include media-breakpoint-up(sm) {
      height: 6em;
    }
    br {
      @include media-breakpoint-down(sm) {
        display: none;
      }
    }
  }
}
