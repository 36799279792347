@use '../accordion';

& {
  width: 100vw;
  max-height: 100vh;
  border-radius: 0;
  border: none;
}

&-header {
  display: flex;
  justify-content: flex-end;
  overflow: hidden;
  padding-top: 10px;
  padding-right: 10px;

  .btn-menu {
    font-size: 44px;
  }
  svg {
    transform: scale(2);
  }
}

&-login {
  padding-bottom: 16px;

  &-title {
    font-size: 18px;
    font-weight: bold;
    color: var(--text0);
    margin-bottom: 8px;
  }
  &-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .btn {
      padding: 9px 37px 11px;
    }
    .def {
      text-decoration: underline;
    }
  }
  .card-user {
    @include gap(12px);
    &-name {
      font-size: 18px;
      margin-bottom: 8px;
      font-weight: bold;
    }
    &-thumbnail {
      &:after {
        content: none;
      }
      .imgWrap {
        width: 80px;
        height: 80px;
      }
    }
  }
}

&-modal {
  padding: 0 !important;
  background-color: var(--bg0);

  &-dialog {
    margin: 0;
    max-width: 100%;
  }
}

// menu
@include accordion.CoverMenu;
@include accordion.modal;
.accordion {
  &-header {
    &-title {
      font-size: 1.14rem;
      font-weight: normal;
    }
  }
  &-button {
    padding-top: 16px;
    padding-bottom: 16px;
    padding-left: var(--bs-gutter-x);
    padding-right: var(--bs-gutter-x);
    &:not(.collapsed) {
      box-shadow: inset 0 -1px 0 var(--super-silver);
    }
  }
  &-item {
    border-left: 0;
    border-right: 0;
    &:first-child {
      border-radius: 0;
    }
    &:last-child {
      border-bottom: 1px solid var(--super-silver);
      border-radius: 0;
    }
  }
  &-body {
    padding: 0;
    ul {
      @include reset-list;
      background-color: var(--zhen-zhu-bai-pearl);
      .list-item {
        color: var(--text1);
      }
      ul {
        background-color: var(--bleached-silk);
        padding-top: 10px;
        padding-bottom: 10px;
        .list-item {
          color: var(--text3);
          padding-top: 10px;
          padding-bottom: 10px;
        }
      }
    }
    > ul > li > .list-item {
      border-bottom: 1px solid var(--super-silver);
    }
    .list-item {
      padding: 15px var(--bs-gutter-x);
      display: block;
      text-decoration: none;
      &.current,
      &:hover {
        color: var(--blue-ruin);
      }
    }
  }
}
