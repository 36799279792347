&-search {
  --paddingX: 20px;
  --icon-width: 20px;
  position: relative;
  @include media-breakpoint-up(sm) {
    max-width: 300px;
  }
  &-submit {
    background-color: transparent;
    box-shadow: none;
    outline: none;
    border: none;
    padding: 0;
    display: block;
    width: 20px;
    height: 20px;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20'%3E%3Cdefs%3E%3Cstyle%3E .cls-2%7Bfill:none;stroke:%23006ddb;stroke-linecap:round;stroke-width:2px%7D %3C/style%3E%3C/defs%3E%3Cg id='icon20_SR' transform='translate(2 2)'%3E%3Cg id='타원_4' class='cls-2'%3E%3Ccircle cx='7.5' cy='7.5' r='7.5'/%3E%3Ccircle cx='7.5' cy='7.5' r='6.5' fill='none'/%3E%3C/g%3E%3Cpath id='선_4' d='M0 0L2.758 2.758' class='cls-2' transform='translate(12.409 12.409)'/%3E%3C/g%3E%3C/svg%3E%0A");
    position: absolute;
    right: var(--icon-width, 20px);
    top: 50%;
    transform: translateY(-50%);
  }
  &-input {
    outline: none;
    border: none;
    width: 100%;
    line-height: 1;
    border-radius: 999px;
    border: solid 1px var(--steam);
    padding: 10px var(--paddingX, 20px);
    padding-right: calc(var(--paddingX, 20px) * 2 + var(--icon-width, 20px));
    transition: border-color 0.2s;
    color: var(--text4);
    &::placeholder {
      color: transparent;
      @include media-breakpoint-up(sm) {
        color: var(--text0);
      }
    }
    &:focus,
    &:valid {
      border: solid 1px var(--blue-ruin);

      + .input-search-suggestions {
        animation: accordionIn 0.45s normal ease-in-out both 1;
      }
    }
    + .input-search-suggestions {
      animation: accordionOut 0.45s normal ease-in-out both 1;
    }
  }
  &-suggestions {
    position: absolute;
    z-index: -1;
    width: 100%;
    top: 0;
    padding-top: 36.75px;
    border-radius: 20px;
    overflow: hidden;
    background-color: var(--bg0);
    border: solid 1px var(--cerebral-grey);
    max-height: 0;

    &-item {
      padding-top: 9px;
      padding-bottom: 9px;
      display: inline-block;
      width: 100%;
      text-decoration: none;
      color: var(--text3);
    }
    &-body {
      @include reset-list;
      max-height: 150px;
      margin: 16px;
      overflow-y: scroll;

      &::-webkit-scrollbar {
        background-color: transparent;
        width: 6px;
        height: 6px;
      }

      &::-webkit-scrollbar-track {
        background-color: var(--zhen-zhu-bai-pearl);
      }

      &::-webkit-scrollbar-thumb {
        background-color: var(--super-silver);
        border-radius: 999px;
      }
    }
  }
}

@keyframes accordionIn {
  0% {
    opacity: 0;
    transform: scale(0.9) rotateX(-60deg);
    transform-origin: 50% 0;
    max-height: none;
  }
  100% {
    opacity: 1;
    transform: scale(1);
    max-height: none;
  }
}

@keyframes accordionOut {
  0% {
    opacity: 1;
    transform: scale(1);
  }
  100% {
    opacity: 0;
    transform: scale(0.9) rotateX(-60deg);
  }
}
