&-header {
  color: var(--text1);
  font-weight: bold;
  font-size: 20px;
  margin-bottom: 24px;

  @include media-breakpoint-up(sm) {
    margin-bottom: 32px;
    font-size: 2.5rem;
  }
}

&-list {
  @include reset-list;
  border-top: 1px solid var(--black-out);
  border-bottom: 1px solid var(--tin);
}
&-item {
  padding-top: 24px;
  @include media-breakpoint-up(sm) {
    padding-top: 48px;
    padding-bottom: 48px;
  }
  &:not(:last-child) {
    border-bottom: 1px solid var(--steam);
  }
  &-row {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    margin-bottom: 24px;
    @include media-breakpoint-up(sm) {
      margin-bottom: 28px;
    }
    @include gap(28px);
    @include media-breakpoint-up(xl) {
      flex-direction: row;
      align-items: center;
    }
  }
  &-content {
    font-size: 18px;
    max-width: 900px;
    color: var(--text3);

    p {
      font-size: 14px;
      @include media-breakpoint-up(sm) {
        font-size: 18px;
      }
    }

    > :last-child {
      margin-bottom: 0;
    }
  }
  &-date {
    font-size: 16px;
    color: var(--dhusar-grey);
  }
  &-tool {
    display: flex;
    @include gap(8px);
    color: var(--text1);

    @include media-breakpoint-up(xl) {
      padding: 8px;
    }

    #{$iconSize} {
      font-size: 20px;
    }
    .icon-label {
      font-weight: 500;
      font-size: 16px;
      white-space: nowrap;
    }
    .btn {
      padding: 8px 12px;
    }
  }
}
