& {
  overflow: hidden;
  position: relative;
}

.main {
  @include media-breakpoint-up(lg) {
    max-width: 440px;
  }
  &-title {
    @include title;
    position: relative;
    padding-top: 23px;
    padding-bottom: 23px;
    margin-top: 0;
    color: var(--invert-text0);
    margin-bottom: 56.25vw;
    @include media-breakpoint-up(lg) {
      margin-bottom: 0;
      padding-top: 67px;
      padding-bottom: 67px;
    }

    &:after {
      content: '';
      display: block;
      position: absolute;
      width: calc(100vw + 2px);
      height: 100%;
      top: 0;
      z-index: -1;
      left: 50%;
      transform: translateX(-50%);
      background-color: var(--theatre-blue);
      @include media-breakpoint-up(lg) {
        --x: calc(-100% + var(--heroImgW, 0px));
        transform: translate(var(--x), 0);
        left: 0;
      }
    }
  }
}

.class {
  &-card {
    font-size: 18px;
    margin-top: 30px;
    margin-bottom: 30px;
    @include media-breakpoint-down(lg) {
      padding-top: 28px;
    }
    .rating-star {
      font-size: 24px;
      margin-bottom: 40px;
    }
  }
  &-link {
    color: var(--theatre-blue);
    text-decoration: none;
    display: inline-block;
    margin-bottom: 16px;
  }
  &-title {
    font-size: 24px;
    @include textDecoration(var(--bg0), var(--theatre-blue));
    @include media-breakpoint-up(sm) {
      font-size: 38px;
    }
  }
  &-content {
    margin-bottom: 30px;
    font-size: 14px;
    @include media-breakpoint-up(sm) {
      margin-bottom: 48px;
      font-size: 18px;
    }
  }
  &-readmore {
    background-color: var(--black-out);
    border-radius: 0;
    color: var(--invert-text0);
    padding: 12px;
    font-size: 14px;

    width: 100%;
    @include media-breakpoint-up(sm) {
      padding: 20px 78px;
      font-size: 18px;
    }
    @include media-breakpoint-up(lg) {
      font-size: 20px;
      width: auto;
    }
  }
}

.video {
  display: flex;
  align-items: flex-end;
  @include media-breakpoint-down(lg) {
    position: absolute;
    left: 50%;
    top: 238px;
    transform: translateX(calc(-50% - 2px));
    padding: 0;
    width: calc(100vw + 4px);
    max-width: none;
  }
  @include media-breakpoint-down(sm) {
    top: 105px;
  }
  video {
    max-width: 100%;
    margin-bottom: 0;
  }
  &-wrap {
    position: relative;
    font-size: 0;
  }
  &-overlay {
    position: absolute;
    z-index: 2;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    left: 0;
    color: var(--invert-text0);
    background-color: rgba(var(--RGB-invert-bg0), 0.5);
    &-control {
      display: flex;
      flex-direction: column;
      align-items: center;
      @supports (gap: 16px) {
        gap: 16px;
      }
      @supports not (gap: 16px) {
        > *:not(:last-child) {
          margin-bottom: 16px;
        }
      }
      svg {
        fill: var(--invert-text0);
        stroke: var(--invert-text0);
      }
    }
    &-text {
      font-size: 20px;
      @include textDecoration(transparent, var(--invert-text0));
    }
  }
}
