@use '../../components/accordion';

.page-account {
  .section {
    margin-top: 32px;
    @include media-breakpoint-up(sm) {
      margin-top: 80px;
      margin-bottom: 80px;
    }
    &:last-child {
      margin-bottom: 140px;
    }
  }
}

.account {
  &-accordion {
    @include accordion.style1;
    border-bottom: 1px solid var(--dhusar-grey);
    &:not(:first-child) {
      margin-top: 100px;
    }
  }

  .review {
    @import './review';
  }
  .review.empty {
    @import './empty';
  }
  .review.write {
    @import './write';
  }
  &-edit {
    @import './edit';
  }
}
