&-product {
  &-thumbnail {
    width: 100%;
    position: relative;
    overflow: hidden;
    margin-bottom: 15px;

    @include media-breakpoint-up(sm) {
      margin-bottom: 28px;
    }

    @include media-breakpoint-up(md) {
      margin-bottom: 40px;
    }

    @supports (object-fit: cover) {
      padding-top: 56.4%;
    }
    img {
      position: absolute;
      @supports (object-fit: cover) {
        width: 100%;
        height: 100%;
        object-fit: cover;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        position: absolute;
      }
      @supports not (object-fit: cover) {
        width: 100%;
      }
    }
  }
  &-title {
    color: var(--text1);
    margin-bottom: 16px;
    font-size: 18px;
    font-weight: bold;
    @include media-breakpoint-up(sm) {
      font-size: 1.7rem;
    }
  }
  &-content {
    color: var(--text4);
    font-size: 12px;
    margin-bottom: 20px;
    @include media-breakpoint-up(sm) {
      font-size: 1rem;
      margin-bottom: 32px;
    }
    > :last-child {
      margin-bottom: 0;
    }
  }
  &-footer {
    display: flex;
    align-items: center;
    position: relative;
    @include gap(24px);
    .icon-wrap {
      @include media-breakpoint-down(sm) {
        @include gap(1px);
      }
    }
    > * {
      position: relative;
    }
    > :not(:last-child) {
      &:after {
        content: '';
        display: block;
        position: absolute;
        width: 1px;
        height: 60%;
        background-color: var(--text5);
        top: 50%;
        right: -12px;
        transform: translateY(-50%);
      }
    }
    .value {
      line-height: 1.6;
    }
  }
}
