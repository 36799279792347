&-circle {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  @include media-breakpoint-down(sm) {
    width: 100px;
    height: 100px;
  }
  @include media-breakpoint-up(lg) {
    width: 140px;
    height: 140px;
  }

  $range: 2, 3, 4, 5, 6, 7, 8, 9, 10;
  @each $unit in $range {
    &[data-range='#{$unit}0'] circle {
      transition: stroke-dashoffset #{($unit * 0.2) + 2}s linear, stroke #{($unit * 0.2) + 2}s linear;
      animation: p#{$unit}0 #{($unit * 0.2) + 2}s forwards;
    }
  }

  svg {
    transform: rotate(270deg);
    @include media-breakpoint-down(sm) {
      transform: rotate(270deg) scale(0.725);
      position: absolute;
    }
    @include media-breakpoint-up(lg) {
      transform: rotate(270deg) scale(1.125);
      position: absolute;
    }
  }
  circle {
    --stroke-color: #deffff;

    transition: stroke-dashoffset 2s linear, stroke 2s linear;
    stroke-linecap: round;

    &:nth-child(1) {
      stroke-width: 2px;
      stroke: var(--simple-serenity);
    }
    &:nth-child(2) {
      stroke-width: 6px;
      stroke: var(--stroke-color);
    }
  }
  .label {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    display: flex;
    flex-direction: column;
    color: var(--text2);
    line-height: 1;
    font-size: 12px;
    @include media-breakpoint-up(sm) {
      font-size: 16px;
    }

    --gap: 10px;
    @supports (gap: var(--gap)) {
      gap: var(--gap);
    }
    @supports not (gap: var(--gap)) {
      > *:not(:last-child) {
        margin-bottom: var(--gap);
      }
    }
  }
  .value {
    color: var(--text1);
    font-size: 1.75em;
    font-weight: 300;
  }
}
