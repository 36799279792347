// font-size
.fs-16 {
  font-size: 16px;
}
.fs-18 {
  font-size: 18px;
}
.fs-20 {
  font-size: 20px;
}
.fs-22 {
  font-size: 22px;
}
// color
.c-text0 {
  color: var(--text0);
}
.c-text1 {
  color: var(--text1);
}
.c-text2 {
  color: var(--text2);
}
.c-text3 {
  color: var(--text3);
}
.c-text4 {
  color: var(--text4);
}

.w-280 {
  width: 280px;
}
