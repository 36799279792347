&-pageheader {
  // page header 요소
  height: var(--barPageheaderHight);
  transition: height 0.3s ease-in-out;
}

@mixin bar-pageheader-samll {
  .bar-bg {
    @include media-breakpoint-up(sm) {
      display: none;
    }
  }
  .bar-breadcrumbs {
    display: none;
  }
}
