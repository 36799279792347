&-page {
  position: relative;
  color: var(--invert-text0);
  height: 200px;

  &-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    padding-top: 20px;
    padding-bottom: 20px;
    overflow: hidden;
  }
  &-left {
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
  &-right {
    display: flex;
    align-items: center;
  }
  &-title {
    font-size: 48px;
    margin: 0;
    margin-bottom: 16px;
    font-weight: bold;
  }
  &-info {
    @include reset-list;

    display: flex;
    font-size: 20px;
    opacity: 0.5;

    @supports (gap: 12px) {
      column-gap: 12px;
      > li:not(:last-child) {
        padding-right: 12px;
      }
    }
    @supports not (gap: 12px) {
      > li:not(:last-child) {
        margin-right: 12px;
      }
    }

    > li:not(:last-child) {
      position: relative;
      &:after {
        content: '';
        display: block;
        position: absolute;
        background-color: var(--invert-text0);
        width: 1px;
        height: 1em;
        top: 50%;
        right: 0;
        transform: translate(100%, -50%);
      }
    }

    .value {
      margin-left: 0.25em;
    }
  }
}
