& {
  .container {
    @include media-breakpoint-up(sm) {
      padding-bottom: 160px;
    }
  }
  .card {
    border-radius: 0;
    border-left: 0;
    border-right: 0;
    border-top: 1px solid var(--black-out);
    border-bottom: 1px solid var(--tin);
    text-align: center;
    padding-top: 32px;
    padding-bottom: 32px;
    @include media-breakpoint-up(sm) {
      padding-top: 72px;
      padding-bottom: 72px;
    }
    &-title {
      margin-bottom: 16px;
      font-size: 20px;
      color: var(--text1);
      @include media-breakpoint-up(sm) {
        font-size: 36px;
      }
    }
    &-content {
      color: var(--text4);
      margin-bottom: 32px;
      word-break: keep-all;
      p {
        font-size: 14px;
        @include media-breakpoint-up(sm) {
          font-size: 18px;
        }
      }
      > :last-child {
        margin-bottom: 0;
      }
    }
    &-footer {
      all: unset;
      font-size: 20px;
      .btn {
        background-color: var(--theatre-blue);
        font-weight: normal;
        font-size: 16px;
        padding-top: 13px;
        padding-bottom: 14px;
      }
    }
  }
}
