& {
  border: none;
  border-radius: 0;
  border-top: 8px solid var(--blueberry-twist);
  width: 240px;
}

&-wrap {
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.2);
  margin-top: 20px !important;
  padding: 0;
}

.dropdown-item {
  padding-left: 24px;
  padding-right: 24px;
}

> li {
  margin-top: calc(14px - 0.25rem);
  margin-bottom: calc(30px - 0.5rem);
}

&-btn {
  background-color: transparent;
  border: none;
  box-shadow: none;
  outline: none;
  display: flex;
  align-items: center;
  padding: 0.25rem 0.5rem;

  span {
    padding-top: 1px;
    margin-right: 8px;
  }
  &:after {
    content: '';
    display: block;
    width: 16px;
    height: 16px;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' id='iocn16_arr_down' width='16' height='16' viewBox='0 0 16 16'%3E%3Cdefs%3E%3Cstyle%3E .cls-2%7Bfill:none;stroke:%23707070;stroke-linecap:round;stroke-width:2px%7D %3C/style%3E%3C/defs%3E%3Cg id='그룹_89' transform='translate(4 7)'%3E%3Cpath id='선_19' d='M0 0L4 4' class='cls-2'/%3E%3Cpath id='선_20' d='M4 0L0 4' class='cls-2' transform='translate(4)'/%3E%3C/g%3E%3C/svg%3E%0A");
  }
  &.disable_arrow:after {
    content: none;
  }
}

&-list {
  @include reset-list;
  margin-top: 0.75rem;
}

&-title {
  &-1 {
    font-size: 1.14em;
    font-weight: 600;
  }
}
