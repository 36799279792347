& {
  overflow: hidden;
}

.main {
  display: flex;
  align-items: center;
  z-index: 2;
  @include media-breakpoint-up(md) {
    padding-top: 120px;
    display: block;
  }
  &-title {
    color: var(--text0);
    @include title;
  }
  &-content {
    color: var(--text5);
    margin-bottom: 50px;
    font-size: 13px;
    @include media-breakpoint-up(sm) {
      font-size: 1rem;
    }
    @include media-breakpoint-up(md) {
      font-size: 16px;
    }
  }
  &-list {
    @include reset-list;
    display: flex;

    @include media-breakpoint-down(md) {
      flex-wrap: wrap;
    }
    @include gap(34px);

    &-item {
      display: flex;
      align-items: center;
      @include gap(16px, x);
      @include media-breakpoint-up(md) {
        display: block;
      }
    }

    &-2 {
      cursor: pointer;
    }

    &-thumb {
      background-color: rgb(36, 100, 172, 0.7);
      border-radius: 0 20px 0 20px;
      padding-top: 27px;
      padding-bottom: 27px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      max-width: 100px;
      @include media-breakpoint-up(md) {
        max-width: 200px;
        margin-bottom: 28px;
      }

      svg {
        height: 54px;
      }
    }

    &-title {
      font-weight: bold;
      color: var(--text2);
      font-size: 18px;
      margin-bottom: 14px;
      @include media-breakpoint-up(md) {
        margin-bottom: 16px;
        font-size: 24px;
      }
    }
    &-content {
      color: var(--text3);
      word-break: keep-all;
      margin-bottom: 0;
      font-size: 12px;
      @include media-breakpoint-up(md) {
        font-size: 1rem;
      }
    }
  }
  &-img {
    width: 100%;
    @include media-breakpoint-down(lg) {
      max-width: 100%;
    }
  }
}
