.search {
  .section {
    margin-top: 80px;
    margin-bottom: 140px;
  }
  &-value {
    @include textDecoration(transparent, var(--invert-text0));
    box-shadow: inset 0 -0.05em transparent, inset 0 -0.05em var(--invert-text0);
    position: relative;
    margin: 0 0.5em;
    color: var(--invert-text0);
    &:before {
      all: unset;
      content: '‘';
      position: absolute;
      left: -0.25em;
      top: 0;
      font-family: AppleSDGothicNeo;
    }
    &:after {
      content: '’';
      position: absolute;
      right: -0.25em;
      top: 0;
      font-family: AppleSDGothicNeo;
    }
    + span {
      opacity: 0.75;
      font-weight: 400;
    }
  }

  &-list {
    @include reset-list;
    border-top: 1px solid var(--dhusar-grey);
    @include media-breakpoint-up(sm) {
      border-bottom: 1px solid var(--dhusar-grey);
    }
    &-header {
      display: flex;
      margin-top: 16px;
      padding-bottom: 16px;
      font-size: 36px;
      color: var(--text1);
      font-weight: bold;
      .value {
        margin-left: 0.5em;
      }
    }
    &-body {
      @include media-breakpoint-up(sm) {
        margin-bottom: 80px;
      }
    }
  }
  &-item {
    margin-bottom: 40px;
    margin-top: 40px;
    min-height: 180px;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    @include gap(20px, x);

    @include media-breakpoint-up(sm) {
      padding-bottom: 40px;
      padding-top: 40px;
      margin-bottom: 0;
      flex-wrap: nowrap;
      border-bottom: 1px solid var(--super-silver);
    }

    &-left {
      display: flex;
      align-items: center;
      @include gap(20px);
      max-width: 100%;
    }
    &-right {
      display: flex;
      flex-direction: column;
    }
    &-left + &-right {
      justify-content: space-around;
    }
    &-thumbnail {
      @include media-breakpoint-up(sm) {
        width: 150px;
        height: 100%;
        position: relative;
      }
      @include media-breakpoint-up(md) {
        width: 260px;
        padding-top: 56.4%;
        height: auto;
      }
      img {
        max-width: 100%;
        @include media-breakpoint-up(sm) {
          position: absolute;
          object-fit: cover;
          width: 100%;
          height: 100%;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }
    }
    &-title {
      margin: 0;
      color: var(--text1);
      font-weight: bold;
      font-size: 20px;
      margin-bottom: 16px;
    }
    &-excerpt {
      color: var(--text4, #888888);
      font-size: 16px;
    }
    &-price {
      font-size: 22px;
      line-height: 1;
      font-weight: bold;
    }
  }
}
