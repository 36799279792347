&-account {
  background-color: var(--ice-fishing);
  overflow: hidden;

  &-infoCol {
    @include media-breakpoint-up(lg) {
      margin-top: 20px;
      margin-bottom: 20px;
    }
    @include media-breakpoint-up(xl) {
      margin-top: 60px;
      margin-bottom: 60px;
    }
    &.left {
      border-bottom: 1px solid var(--common-feldspar);
      padding-bottom: 28px;
      padding-top: 32px;
      .card-user {
        @include media-breakpoint-up(lg) {
          flex-direction: column;
        }
        @include media-breakpoint-up(xl) {
          flex-direction: row;
        }
      }
      @include media-breakpoint-up(xl) {
        padding-top: 0;
        padding-bottom: 0;
        padding-right: 52px;
        border: none;
        border-right: 1px solid var(--common-feldspar);
      }
    }
    &.right {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      @include gap(24px);
      padding-top: 28px;
      padding-bottom: 32px;
      justify-content: center;
      @include media-breakpoint-up(xl) {
        padding-top: 0;
        padding-bottom: 0;
        padding-left: 52px;
        @include gap(32px);
        flex-wrap: nowrap;
        justify-content: flex-start;
      }

      .icon-wrap {
        font-size: 18px;
        color: var(--text1);
        @include media-breakpoint-up(md) {
          font-size: 22px;
        }

        #{$iconSize} {
          font-size: 28px;
          @include media-breakpoint-up(md) {
            font-size: 32px;
          }
        }
      }
    }
  }
  .education {
    &-status {
      @include media-breakpoint-down(xl) {
        display: flex;
        justify-content: space-between;
        width: 100%;
      }
      &-label {
        color: var(--blue-ruin);
        text-decoration: none;
        display: flex;
        align-items: center;
        font-size: 18px;
        @include gap(4px);
        @include media-breakpoint-up(md) {
          font-size: 2rem;
          white-space: nowrap;
        }
        &:after {
          content: '';
          display: block;
          width: 1em;
          height: 1em;
          background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='28' height='28' viewBox='0 0 28 28'%3E%3Cdefs%3E%3Cstyle%3E .cls-2%7Bfill:none;stroke:%239db5ca;stroke-linecap:round;stroke-width:2.4px%7D %3C/style%3E%3C/defs%3E%3Cg transform='translate(9.06 0.8)'%3E%3Cg transform='translate(2.333 3.167)'%3E%3Cpath d='M0 0L8 8' class='cls-2' transform='translate(-1.393 2.033)'/%3E%3Cpath d='M0 8L8 0' class='cls-2' transform='translate(-1.393 10.033)'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E%0A");
          background-position: center;
          background-repeat: no-repeat;
          @include media-breakpoint-down(sm) {
            background-size: cover;
            margin-top: 1px;
            font-size: 19px;
          }
        }
      }
    }
    &-progress {
      display: flex;
      @include media-breakpoint-up(lg) {
        @include gap(24px);
      }
    }
    &-due {
      .label {
        font-size: 12px;
        text-align: center;
        display: flex;
        flex-direction: column;
        line-height: 1;
        color: var(--text2);

        @include media-breakpoint-up(sm) {
          font-size: 16px;
        }

        --gap: 10px;
        @supports (gap: var(--gap)) {
          gap: var(--gap);
        }
        @supports not (gap: var(--gap)) {
          > *:not(:last-child) {
            margin-bottom: var(--gap);
          }
        }
      }
      .value {
        font-size: 1.75em;
        color: var(--text1);
      }
    }
  }
  .tab {
    @include media-breakpoint-down(sm) {
      width: calc(100vw + 2px);
      transform: translateX(calc(var(--bs-gutter-x) * -1));
    }
  }
  .tab-item {
    font-size: 16px;
    border: 1px solid var(--simple-serenity);
    padding: 12px;
    @include media-breakpoint-up(sm) {
      padding: 17px;
    }
    @include media-breakpoint-up(md) {
      padding: 1.5rem;
      font-size: 24px;
    }
  }
}
