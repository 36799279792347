.shop {
  &-list {
    @include reset-list;
    &-header {
      display: flex;
      justify-content: space-between;
      margin-top: 16px;
      padding-bottom: 16px;
    }
    &-body {
      margin-bottom: 36px;
      border-top: 1px solid var(--black-out);
      border-bottom: 1px solid var(--black-out);
    }
  }
  &-tool {
    @include reset-list;
    @include gap(8px, x);
    display: flex;

    .btn {
      border-radius: 2px;
      border: 1px solid var(--cerebral-grey);
      color: var(--text3);
      &[disable] {
        background-color: var(--super-silver);
      }
    }
  }
  &-item {
    padding-bottom: 24px;
    padding-top: 24px;
    border-bottom: 1px solid var(--super-silver);
    display: flex;
    flex-wrap: nowrap;
    width: 100%;
    @include gap(20px, x);

    &-left {
      display: flex;
      align-items: center;
      @include gap(20px);
    }
    &-right {
      display: flex;
      font-size: 18px;
      flex-direction: column;
      justify-content: space-between;
    }
    &-thumbnail {
      img {
        height: 100px;
      }
    }
    &-title {
      font-size: 1em;
      margin: 0;
      color: var(--text1);
      font-weight: bold;
    }
    &-description {
      color: var(--text4, #888888);
    }
    &-price {
      font-size: 22px;
      line-height: 1;
      font-weight: bold;
    }
  }
  &-payment {
    @include reset-list;
    padding-top: 24px;
    padding-bottom: 24px;
    > :not(:last-child) {
      margin-bottom: 16px;
    }
    &-total {
      color: var(--text1);
      .price {
        font-weight: bold;
      }
    }
    &-item {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
}
