svg.simple-flow-line {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  color: var(--cerebral-grey);
}

.roadmap {
  &-canvas {
    position: relative;
    > .row {
      --bs-gutter-x: 60px;
    }
  }
  &-left {
    display: none;
    @include media-breakpoint-up(xl) {
      display: flex;
      flex-direction: column;
    }
  }
  &-row {
    justify-content: center;
    > [class*='col'] {
      display: flex;
      flex-direction: column;
    }
  }
  [data-link='key-11'],
  [data-link='key-10'] {
    visibility: hidden;
    @include media-breakpoint-up(xl) {
      visibility: visible;
    }
  }
}
