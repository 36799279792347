@at-root {
  :root {
    --fnb-bg: #efefef;
    --fnb-border: #cccccc;
    --fnb-color: var(--text4);
  }
}

.fnb {
  background-color: var(--fnb-bg);
  color: var(--fnb-color);

  &-container {
    padding-top: 16px;
    padding-bottom: 16px;
    @include media-breakpoint-up(sm) {
      padding-top: 34px;
      padding-bottom: 34px;
    }
    > .row > [class*='col'] {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }
  &-list {
    &-logo {
      @include reset-list;
      display: flex;
      align-items: flex-end;
      flex-wrap: wrap;

      @supports (gap: 40px) {
        column-gap: 40px;
        row-gap: 28px;
        margin-bottom: 28px;
      }
      @supports not (gap: 40px) {
        > li {
          margin-bottom: 28px;
        }
        > li:not(:last-child) {
          margin-right: 40px;
        }
      }

      @include media-breakpoint-down(sm) {
        margin-bottom: calc(24px - 1rem);
        img {
          max-height: 32px;
        }
        @include gap(20px);
      }
    }

    &-info {
      @include reset-list;
      display: flex;
      flex-wrap: wrap;
      font-size: 12px;
      position: relative;

      @include media-breakpoint-down(sm) {
        flex-direction: column;
      }
      @include media-breakpoint-up(sm) {
        font-size: 13px;
        margin-bottom: 20px;
      }

      li {
        margin-bottom: 7px;
      }
      @supports (gap: 8px) {
        column-gap: 8px;
        > li:not(:last-child) {
          padding-right: 8px;
        }
      }
      @supports not (gap: 8px) {
        > li:not(:last-child) {
          margin-right: 8px;
        }
      }
      a {
        color: var(--fnb-color);
        text-decoration: none;
        &:hover {
          text-decoration: underline;
        }
      }
      > li:not(:last-child) {
        position: relative;
        &:after {
          display: block;
          position: absolute;
          background-color: var(--fnb-border);
          width: 1px;
          height: 1em;
          top: 50%;
          right: 0;
          transform: translate(100%, -50%);
          @include media-breakpoint-up(sm) {
            cotent: '';
          }
        }
      }
    }

    &-pagenav {
      @include reset-list;

      --px: 36px;

      @include media-breakpoint-down(sm) {
        font-size: 12px;
      }

      @include media-breakpoint-up(xl) {
        padding-left: var(--px);
        padding-right: var(--px);
      }

      > li:not(:last-child):not([class*='title']) {
        margin-bottom: 2px;
      }
      a {
        color: var(--fnb-color);
        text-decoration: none;
        &:hover {
          text-decoration: underline;
        }
      }
      &-wrap {
        height: 100%;
        display: flex;
        flex-wrap: wrap;
        @supports (gap: 60px) {
          column-gap: 60px;
        }
        @include media-breakpoint-up(xl) {
          justify-content: space-between;
          column-gap: 0;
        }
        > .fnb-list-pagenav {
          @include media-breakpoint-down(xl) {
            @supports not (gap: 60px) {
              &:not(:last-child) {
                margin-right: 60px;
              }
            }
          }
          @include media-breakpoint-up(xl) {
            border-left: 1px solid var(--fnb-border);
          }
        }
      }

      &-title {
        font-size: 14px;
        font-weight: 500;
        margin-bottom: 4px;
        color: var(--text2);
        width: 100%;
        @include media-breakpoint-up(sm) {
          padding-top: 10px;
          font-weight: bold;
        }
      }

      &-com {
        display: flex;
        flex-wrap: wrap;
        @include gap(12px);
        row-gap: 7px !important;
        li {
          margin-bottom: 0 !important;
        }
        @include media-breakpoint-up(xl) {
          flex-direction: column;
        }
      }
    }
  }
  &-copylight {
    @include media-breakpoint-down(sm) {
      font-size: 12px;
    }
  }
  &-menu {
    @include media-breakpoint-down(sm) {
      order: -1;
      margin-bottom: 4px;
    }
  }
}
