& {
  width: 1em;
  height: 1em;
  background-color: transparent;
  box-shadow: none;
  outline: none;
  border: none;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Cdefs%3E%3Cstyle%3E .cls-2%7Bfill:none;stroke:%23615b5f;stroke-linecap:round;stroke-width:2px%7D %3C/style%3E%3C/defs%3E%3Cg transform='translate(-12 -95.5)'%3E%3Cpath d='M0 0h20' class='cls-2' transform='translate(14 107.5)'/%3E%3Cpath d='M0 9l3.937-3.937L5.25 3.75 9 0' class='cls-2' transform='translate(14 98.5)'/%3E%3Cpath d='M0 0l3.938 3.938.562.562L9 9' class='cls-2' transform='translate(14 107.5)'/%3E%3C/g%3E%3C/svg%3E%0A");
}
