@at-root {
  .page-classSingle {
    --barPageheaderHight: 220px;
    @include media-breakpoint-up(sm) {
      --barPageheaderHight: 200px;
    }
  }
}

.s1 {
  @include media-breakpoint-down(sm) {
    margin-top: 0;
    margin-bottom: 0;
  }
  &-container {
    display: flex;
    flex-direction: column;
    @include media-breakpoint-down(sm) {
      padding-left: 0;
      padding-right: 0;
    }
  }

  &-top-header {
    margin-top: 58px;
    margin-bottom: 58px;
    position: relative;

    @include media-breakpoint-up(lg) {
      margin-bottom: 85px;
    }

    .btn-arrow-group {
      text-decoration: none;
      color: #666666;
      line-height: 1;

      i {
        font-size: 34px;
        @include media-breakpoint-up(lg) {
          font-size: 50px;
          margin-bottom: 14px;
        }
      }
      .label {
        font-size: 16px;

        @include media-breakpoint-down(lg) {
          display: none;
        }
      }
    }
    .btn-arrowPrev-group {
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      @include media-breakpoint-up(lg) {
        transform: translateY(-1.4em);
      }
    }
    .btn-arrowNext-group {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      @include media-breakpoint-up(lg) {
        transform: translateY(-1.4em);
      }
    }

    &-content {
      text-align: center;
      color: var(--black-out);
      padding-left: 28px;
      padding-right: 28px;

      @include media-breakpoint-up(lg) {
        padding-left: 68px;
        padding-right: 68px;
      }

      .titleWrap {
        position: relative;
      }

      .title {
        font-size: 24px;
        font-weight: bold;
        line-height: 36px;
        margin-bottom: 20px;
        padding-left: 48px;
        padding-right: 48px;
        @include media-breakpoint-up(lg) {
          margin-bottom: 13px;
          line-height: 43px;
          font-size: 34px;
          padding: 0;
        }
      }
      .title2 {
        font-size: 16px;
        line-height: 26px;
        font-weight: bold;

        @include media-breakpoint-up(lg) {
          font-size: 20px;
          line-height: 24px;
        }
      }
    }
  }

  &-header {
    @include media-breakpoint-down(lg) {
      order: 99;
    }

    @include media-breakpoint-up(sm) {
      display: flex;
      justify-content: space-between;
      padding-top: 28px;
      padding-bottom: 28px;
      margin-bottom: 60px;
      border-bottom: 1px solid #707070;
      border-top: 1px solid #707070;
    }

    a {
      text-decoration: none;
      color: currentColor;
      @include media-breakpoint-down(sm) {
        padding: 15px 31px;
        border-bottom: 1px solid var(--steam);
        color: var(--text1);
        &:first-child {
          border-top: 1px solid var(--steam);
        }
      }
    }
    &-left {
      display: flex;
      flex-direction: column;

      @include media-breakpoint-up(sm) {
        flex-direction: row;
        @include gap(50px);
        > *:not(:last-child):after {
          content: '';
          display: block;
          position: absolute;
          right: 0;
          top: 50%;
          width: 1px;
          height: 28px;
          background-color: var(--dhusar-grey);
          transform: translate(25px, -50%);
        }
      }
      > * {
        position: relative;
      }
      .icon-label {
        font-weight: bold;
      }
    }
    &-right {
      @include media-breakpoint-down(sm) {
        display: flex;
        flex-direction: column;
        a {
          border-top: none !important;
        }
      }
    }
    #{$iconSize} {
      font-size: 24px;
    }
    .icon-wrap {
      @include gap(8px);
    }
    .icon-label {
      font-size: 18px;
      color: var(--black-out);

      @include media-breakpoint-up(sm) {
        font-size: 16px;
      }
    }
  }
  &-content {
    padding-top: 56.4%;
    position: relative;

    @include media-breakpoint-up(lg) {
      margin-bottom: 80px;
    }
    @include media-breakpoint-up(sm) {
      margin-top: 40px;
      margin-bottom: 140px;
    }
    iframe {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  &-footer {
    text-align: center;
    padding-bottom: 40px;

    @include media-breakpoint-down(lg) {
      display: none;
    }
  }
}
