& {
  padding: 0;
  position: absolute;
  top: var(--barHeight);
  right: 0;
  width: 240px;
  height: 100%;
  max-height: calc(100vh - var(--barHeight));
  background-color: var(--bg0);
  @include reset-list;
}

&-item {
  padding: 16px 24px;
  margin: 0;
  &:not(:last-child) {
    border-bottom: 1px solid var(--super-silver);
  }
}
&-header {
  font-size: 16px;
  color: var(--text1);
  margin-bottom: 8px;
}
&-content {
  color: var(--text3);
  a {
    color: var(--blue-ruin);
  }
  .history,
  b {
    color: var(--text0);
  }
}
