@use 'sass:selector';
@use '../../components/accordion';

@mixin unify-parent($child) {
  @at-root #{selector.unify(&, $child)} {
    @content;
  }
}

.class {
  // layout
  &.cat {
    .s1 {
      @import './section1';
    }
    .s2 {
      @import './section2';
    }
  }
  &.single {
    @import './single';
  }
  // style
  font-size: 1rem;

  @include media-breakpoint-up(sm) {
    font-size: 1.125rem;
  }
  .section {
    margin-top: 40px;
    margin-bottom: 40px;
    @include media-breakpoint-up(sm) {
      margin-top: 80px;
      margin-bottom: 80px;
    }
  }
  &-title-1 {
    font-size: 24px;
    font-weight: bold;
    margin-top: 0;
    margin-bottom: 8px;
    color: var(--text1);

    @include media-breakpoint-up(sm) {
      font-size: 36px;
    }
    + p {
      margin-bottom: 0;
    }
  }
  &-goal {
    &-thumb {
      margin-bottom: 24px;
      @include media-breakpoint-up(sm) {
        margin-bottom: 32px;
      }
      img {
        max-width: 100%;
      }
    }
    &-info {
      @include reset-list;
      margin-bottom: 32px;

      @include media-breakpoint-up(sm) {
        margin-bottom: 40px;
      }

      #{$iconSize} {
        font-size: 32px;
      }
      .icon-label {
        font-weight: bold;
      }

      @supports (gap: 20px) {
        display: flex;
        flex-direction: column;
        gap: 20px;
      }
      @supports not (gap: 20px) {
        > li:not(:last-child) {
          margin-bottom: 20px;
        }
      }

      &-item {
        display: flex;
        padding-left: 4px;
        flex-direction: column;
        @include gap(12px, y);

        @include media-breakpoint-up(sm) {
          flex-direction: row;
          align-items: center;
        }
        @include media-breakpoint-up(md) {
          flex-direction: column;
          align-items: unset;
        }
        @include media-breakpoint-up(lg) {
          flex-direction: row;
          align-items: center;
          @include gap(28px, x);
        }

        &.items-start {
          align-items: flex-start;
        }

        .icon-wrap {
          min-width: 100px;
          --gap: 8px;
          color: var(--text1);
        }
      }
    }
    &-btnWrap {
      display: flex;

      @supports (gap: 1px) {
        gap: 1px;
      }
      @supports not (gap: 1px) {
        > *:not(:last-child) {
          margin-right: 1px;
          margin-bottom: 1px;
        }
      }

      #{$iconSize} {
        font-size: 24px;
        @include media-breakpoint-up(lg) {
          font-size: 32px;
        }
      }
      .icon-label {
        font-size: 14px;
        @include media-breakpoint-up(md) {
          font-size: 16px;
        }
        @include media-breakpoint-up(lg) {
          font-size: 20px;
        }
      }

      .btn {
        border-radius: 0;
        width: 100%;
        color: var(--invert-text0);
        text-align: center;
        padding-top: 16px;
        padding-bottom: 16px;
        display: flex;
        justify-content: center;
      }
      .btn.down {
        background-color: var(--black-out);
      }
      .btn.mov {
        background-color: var(--theatre-blue);
      }
    }
  }
  &-accordion {
    // use bootstrap accordion
    @include accordion.style1;
  }
  &-review {
    font-size: 15px;
    @include media-breakpoint-up(sm) {
      font-size: 18px;
    }

    &-item {
      padding-bottom: 32px;
    }

    .card-review {
      height: 100%;
    }
  }
}
