& {
  overflow: hidden;
}

&-title {
  max-width: 370px;
  @include title;
  color: var(--text1);
  line-height: 1.4;
  @include media-breakpoint-down(sm) {
    margin-bottom: 28px;
  }
}

&-levels {
  display: flex;
  align-items: center;
  position: relative;

  justify-content: flex-start;
  @include media-breakpoint-up(md) {
    justify-content: flex-end;
  }

  &:after,
  &:before {
    display: none;
    @include media-breakpoint-up(md) {
      display: block;
    }
  }
  &:after {
    content: '';
    width: calc(100% + 100vw);
    background-color: var(--cerebral-grey);
    position: absolute;
    height: 1px;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
  }
  &::before {
    content: '';
    width: 10px;
    height: 10px;
    background-color: var(--azure);
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    z-index: 3;
    border-radius: 999px;
    box-shadow: 0 0 0 2px var(--blue-hijab) inset;
  }
  .nav {
    z-index: 2;
    overflow: hidden;
    position: relative;
    @include gap(8px);
    @include media-breakpoint-up(sm) {
      @include gap(24px);
    }
    @include media-breakpoint-up(md) {
      @include gap(28px);
      overflow: visible;
    }
  }
  .nav-item {
    position: relative;
  }
  .link-tooltip {
    display: none;
  }
  .nav-link {
    width: 40px;
    height: 40px;
    padding: 0;
    border-radius: 999px;
    background-color: var(--cold-morning);
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    @include gap(2px);

    @include media-breakpoint-up(sm) {
      width: 48px;
      height: 48px;
    }

    &.active + .link-tooltip {
      position: absolute;
      width: 90px;
      height: 34px;
      color: var(--theatre-blue);
      background-color: var(--bg0);
      font-weight: bold;
      box-shadow: 3px 3px 9px 0 rgba(0, 0, 0, 0.16);
      bottom: 100%;
      left: 50%;
      transform: translateX(-50%);
      align-items: center;
      justify-content: center;

      display: none;
      @include media-breakpoint-up(md) {
        display: flex;
      }
      &:after {
        width: 0;
        height: 0;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-top: 8px solid var(--bg0);
        content: ' ';
        position: absolute;
        top: 100%;
        left: 50%;
        transform: translateX(-50%);
      }
    }

    .icon-star {
      font-size: 8px;
      @include media-breakpoint-up(sm) {
        font-size: 12px;
      }
    }
    .icon-star.active {
      display: none;
    }

    &.active {
      background-color: var(--blue-ruin);
      .icon-star {
        display: none;
      }
      .active {
        display: block;
      }
    }
  }
}

&-product {
  margin-top: 60px;
  .card-product {
    margin-bottom: 40px;
    @include media-breakpoint-up(sm) {
      margin-bottom: 60px;
    }
    @include media-breakpoint-down(sm) {
      .icon-label {
        margin-left: 4px;
      }
    }
  }
}
