@at-root {
  #{$gp} {
    @include media-breakpoint-up(lg) {
      --heroImgW: 820px;
    }
  }
}

& {
  --swiperH: 520px;
  --contentH: 247px;
  --mb: 0px;
  margin-bottom: 0;
  @include media-breakpoint-up(md) {
    --mb: 48px;
    --contentH: 326px;
    --swiperH: 476px;
  }
}

&-swiper {
  height: calc(var(--swiperH) + var(--mb));
  padding-bottom: var(--mb);
  &-wrapper {
    background-color: var(--blueberry-twist);
  }
  &-pagination {
    position: relative;
    display: flex;
    height: 42px;

    @include media-breakpoint-up(md) {
      padding-left: 72px;
    }

    &-container {
      position: relative;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      @include media-breakpoint-up(md) {
        flex-wrap: nowrap;
      }
      .fake {
        max-width: var(--heroImgW);
        width: 100%;
        height: 10px;
      }
    }
    &-wrap {
      position: absolute;
      bottom: var(--mb);
      width: 100%;
    }
    &-bullet {
      width: 52px;
      height: 100%;
      position: relative;
      background-color: transparent;
      outline: none;
      box-shadow: none;
      &:after {
        content: '';
        display: block;
        position: absolute;
        height: 2px;
        width: 100%;
        border-radius: 6px;
        opacity: 0.2;
        background-color: var(--bg0);
        transition: opacity 0.3s;
      }
      &:not(:last-child) {
        margin-right: 12px;
      }
      &.swiper-pagination-bullet-active:after {
        opacity: 1;
      }
    }
  }
}
&-slide {
  &-container {
    position: relative;
    height: 100%;
    @include media-breakpoint-down(md) {
      padding: 0;
      max-width: 100%;
    }
  }
  &-img {
    --currentH: 100px;
    max-width: var(--heroImgW);
    height: calc(var(--swiperH) - var(--currentH));
    position: relative;
    z-index: 1;
    transform: translateY(var(--mb));
    @include media-breakpoint-up(md) {
      --currentH: 0px;
    }
    img {
      position: absolute;
      object-fit: cover;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
      height: 100%;
      user-select: none;
    }
  }
  &-content {
    position: absolute;
    z-index: 2;
    height: var(--contentH);
    background-color: var(--blue-ruin);
    text-align: center;
    color: var(--invert-text0);
    line-height: 1.2;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1rem;
    width: 320px;
    max-width: 90%;
    left: 50%;
    bottom: 78px;
    transform: translateX(-50%);
    @include media-breakpoint-up(md) {
      width: 520px;
      max-width: none;
      transform: translateY(-50%);
      right: 0;
      left: auto;
      bottom: auto;
      top: 50%;
    }

    &::before,
    &::after {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
    &::before {
      background-color: var(--blue-ruin);
      z-index: -1;
    }
    &::after {
      z-index: -2;
      opacity: 0.2;
      -webkit-filter: blur(4px);
      filter: blur(4px);
      background-color: #163760;
      transform: translate(20px, 20px);
    }
    &-row {
      margin-bottom: 32px;
    }
    &-text {
      &-1 {
        background-size: 1px 1em;
        @include textDecoration(var(--blue-ruin), var(--invert-text0));
        font-size: 28px;
        font-weight: bold;
        @include media-breakpoint-up(md) {
          font-size: 44px;
        }
      }
      &-2 {
        opacity: 0.75;
        font-size: 14px;
        line-height: 1.7;
        @include media-breakpoint-up(md) {
          font-size: 16px;
        }
      }
    }
  }
}

// swiper
.swiper {
  &-navigation {
    position: absolute;
    z-index: 2;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    .container {
      position: relative;
    }
    &-button {
      position: absolute;
      top: 50%;
      color: var(--invert-text0);
      &.prev {
        --x: calc(-60px - 100%);
        left: 0;
        transform: translate(var(--x), -50%);
      }
      &.next {
        --x: calc(100% + 60px);
        right: 0;
        transform: translate(var(--x), -50%);
      }
    }
  }
}
