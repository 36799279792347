& {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  border: none;
  color: var(--invert-text0);
  font-size: 18px;
  border-radius: 2px;
  white-space: nowrap;
  width: 150px;
  &.primary {
    background-color: var(--theatre-blue);
  }
  &.dark {
    background-color: var(--black-out);
  }
}
