& {
  @include media-breakpoint-down(sm) {
    margin-top: 0;
  }
}

&-col-header {
  border-top: 1px solid var(--black-out);
  margin-bottom: 28px;
  padding-top: 54px;
  @include media-breakpoint-up(sm) {
    padding-top: 80px;
  }
}
