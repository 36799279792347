.input {
  @import './search';

  &-item {
    border: 1px solid var(--steam);
    border-radius: 2px;
    transition: border-color 0.3s;
    color: var(--text1);
    padding: 10px 20px;
    border-radius: 2px;
    line-height: 1.6;
    outline: none;
    min-width: 0;
    font-size: 16px;

    @include media-breakpoint-up(sm) {
      font-size: 18px;
      padding: 16px 24px;
    }

    &::placeholder {
      color: var(--text4);
    }
    &:focus {
      border-color: var(--black-out);

      &::placeholder {
        color: var(--text1);
      }
    }
    &:read-only {
      border-color: transparent;
    }
  }
  &-grid {
    @include gap(12px, y);
    display: flex;
  }
  &-inputWrap {
    display: flex;
    flex-direction: column;
    @include gap(12px);
    width: 100%;
    &.email {
      flex-direction: row;
      align-items: center;
      input {
        width: 100%;
      }
    }
  }
  &-label {
    display: flex;
    align-items: center;
    @include gap(8px);
    &.left {
      > span {
        order: -1;
        width: 162px;
      }
    }
    &.column {
      flex-direction: column;
      align-items: flex-start;

      > input {
        width: 100%;
      }
      > span {
        order: -1;
      }
    }
  }
  &-description {
    color: var(--text4);
  }
}
