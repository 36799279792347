& {
  width: 1em;
  height: 1em;
  background-color: transparent;
  box-shadow: none;
  outline: none;
  border: none;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  padding: 0;

  &.white {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Cdefs%3E%3Cstyle%3E .cls-2%7Bfill:none;stroke:%23fff;stroke-linecap:round;stroke-width:2px%7D %3C/style%3E%3C/defs%3E%3Cg class='cls-2' transform='translate(1 1)'%3E%3Ccircle cx='9' cy='9' r='9'/%3E%3Ccircle cx='9' cy='9' r='8' fill='none'/%3E%3C/g%3E%3Cpath d='M0 0L4.1 4.1' class='cls-2' transform='translate(16.5 16.5)'/%3E%3C/svg%3E%0A");
  }
}
