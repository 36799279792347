& {
  &-container {
    height: var(--barGnbHeight);
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    @include media-breakpoint-down(sm) {
      border-bottom: 1px solid var(--steam);
    }
  }

  &-title {
    font-size: 20px;
    font-weight: 500;
    color: var(--text1);
  }

  .btn-pre {
    position: absolute;
    left: var(--bs-gutter-x);
    top: 50%;
    transform: translateY(-50%);
    font-size: 24px;
    padding: 0;
  }
}
