.signup {
  .singleSection {
    overflow-x: hidden;
  }
  &-tab {
    margin-bottom: 60px;
    @include media-breakpoint-down(lg) {
      display: block;
    }
    .btn {
      font-size: 18px;
      padding-top: 22px;
      padding-bottom: 21px;
      @include media-breakpoint-up(sm) {
        font-size: 24px;
      }
    }
  }
  &-content {
    padding-top: 60px;
    border-top: 1px solid var(--black-out);
    margin-bottom: 52px;

    @include media-breakpoint-up(lg) {
      border-bottom: 1px solid var(--black-out);
    }
    .btn.tool {
      border: solid 1px var(--steam);
    }
    > .input-label {
      margin-bottom: 28px;
      font-size: 18px;
      justify-content: center;
      align-items: flex-start;
      @include media-breakpoint-down(lg) {
        flex-direction: column;
      }
      .unit {
        display: flex;
        align-items: center;
      }
      [required] + span:not([class]):after {
        content: '*';
        margin-left: 8px;
        color: var(--blue-ruin);
        font-size: 1.2em;
        vertical-align: text-top;
      }
      .input-grid {
        @include media-breakpoint-down(lg) {
          flex-direction: column;
        }
      }
      .email {
        display: flex;
        align-items: center;
        input {
          width: 100%;
        }
        @include gap(8px);
        > span {
          color: var(--text2);
          padding-top: 15px;
        }
      }
      .dropdown-wrap {
        display: flex;
        @include gap(8px);
      }
    }

    > .input-label > :first-child {
      max-width: 610px;
      width: 100%;
      align-items: stretch;
    }
  }
  &-consent {
    padding: 52px 20px;
    background-color: var(--whiteout);
    border-top: 1px solid var(--steam);
    margin-top: 61px;
    @include media-breakpoint-down(lg) {
      width: 100vw;
      border-bottom: 1px solid var(--black-out);
      transform: translateX(calc(var(--bs-gutter-x) * -1));
    }
    &-list {
      @include reset-list;
      max-width: 780px;
      margin: auto;
    }
  }
  &-footer {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
  }
}
