& {
  line-height: 0;
  width: 1em;
  height: 1em;
  overflow: hidden;

  svg {
    cursor: pointer;
    width: 100%;
    height: 100%;
  }
  input {
    display: none;
  }
  path {
    fill: none;
    stroke: currentColor;
    stroke-width: 3;
    stroke-linecap: round;
    stroke-linejoin: round;
    --length: 24;
    --offset: -38;
    stroke-dasharray: var(--length) var(--total-length);
    stroke-dashoffset: var(--offset);
    transition: all 0.8s cubic-bezier(0.645, 0.045, 0.355, 1);
  }

  circle {
    fill: currentColor;
    opacity: 0;
  }

  label:hover circle {
    opacity: 1;
  }

  body.modal-open &.cross svg {
    .line--1,
    .line--3 {
      --length: 22.627416998;
    }
    .line--2 {
      --length: 0;
    }
  }

  .line--1,
  .line--3 {
    --total-length: 126.64183044433594;
  }
  .line--2 {
    --total-length: 70;
  }
  body.modal-open & svg {
    .line--1,
    .line--3 {
      --offset: -94.1149185097;
    }
    .line--2 {
      --offset: -50;
    }
  }
}
