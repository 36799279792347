@use '../functions/textDecoration' as *;
@use '../functions/gap' as *;
@use '../functions/ui' as ui;
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

// bootstrap accordion
@mixin style1 {
  .accordion {
    &-header {
      &-main {
        padding-right: 20px;
        align-self: center;
      }
      &-title {
        font-size: 20px;
        font-weight: bold;
      }
      &-description {
        margin-top: 16px;
        display: none;
        color: var(--text4);
        font-size: 13px;
        line-height: 1.6;
        font-weight: normal;
        @include media-breakpoint-up(sm) {
          font-size: 16px;
        }
      }
    }
    &-button {
      padding: 28px 0;
      position: relative;
      align-items: center;
      color: var(--text1);
      box-shadow: none;

      &:after {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='28' height='28' viewBox='0 0 28 28'%3E%3Cg%3E%3Cpath fill='%23ccc' d='M8.642 12.916L.473 3.266A2.055 2.055 0 0 1 .595.508a1.731 1.731 0 0 1 2.568.131L12.444 11.6a2.06 2.06 0 0 1 0 2.626L3.163 25.194a1.731 1.731 0 0 1-2.568.131 2.055 2.055 0 0 1-.122-2.758z' transform='translate(28) rotate(90) translate(7.083 1.167)'/%3E%3C/g%3E%3C/svg%3E%0A");
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        width: 24px;
        height: 24px;
        margin-right: 8px;
        @include media-breakpoint-up(sm) {
          width: 28px;
          height: 28px;
        }
      }
      &:not(.collapsed) {
        background-color: transparent;
        border: none;
        box-shadow: inset 0 -1px 0 var(--steam);
        &:after {
          background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Cg%3E%3Cpath fill='%23888' d='M7.253 10.841L.4 18.941a1.725 1.725 0 0 0 .1 2.315 1.453 1.453 0 0 0 2.155-.11l7.789-9.2a1.729 1.729 0 0 0 0-2.2L2.655.536A1.453 1.453 0 0 0 .5.426a1.725 1.725 0 0 0-.1 2.315z' transform='translate(0 23.5) rotate(-90) translate(5.945 0.839)'/%3E%3C/g%3E%3C/svg%3E%0A");
          transform: rotate(0);
        }
      }
    }

    // body
    &-body {
      padding: 28px 0;

      &-header {
        display: flex;
        margin-bottom: 28px;
        .text-underline {
          color: var(--text0);
          font-size: 18px;
          font-weight: bold;
          line-height: 1;
          padding-bottom: 1px;
          @include textDecoration(var(--bg0), var(--text0));
        }
      }
      &-list {
        position: relative;
        @include ui.reset-list;
        margin-bottom: 8px;

        &:after {
          content: '';
          display: block;
          position: absolute;
          width: 1px;
          height: calc(100% - 32px);
          background-color: var(--steam);
          left: 6px;
          top: 50%;
          transform: translate(-50%, -50%);
        }
        &-item {
          position: relative;
          padding-left: 20px;
          z-index: 2;
          font-size: 16px;
          color: var(--text4);
          display: flex;
          flex-direction: column;

          &-header {
            display: flex;
            align-items: center;
            justify-content: space-between;
          }

          &:not(:last-child) {
            margin-bottom: 24px;
          }
          &.done {
            --bar: var(--simply-blue);
            --label: var(--simply-blue);
            color: var(--text2);
            &:after {
              border: none;
              background-color: var(--blue-ruin);
            }
            .link {
              border-color: var(--cerebral-grey);
              background-color: var(--super-silver);
              color: var(--text3);
            }
            .time {
              color: var(--text3);
            }
            .title::after {
              border: none;
              background-color: var(--blue-ruin);
            }
          }
          &.current {
            --bar: linear-gradient(to right, var(--blue-ruin) 13%, #00aced 89%);
            --label: var(--blue-ruin);
            color: var(--blue-ruin);
            font-weight: 700;
            &::after {
              border-color: var(--blue-ruin);
            }
            .link {
              border-color: var(--blue-ruin);
              background-color: var(--bg0);
              color: var(--blue-ruin);
              order: 100;
            }
            .title::after {
              border-color: var(--blue-ruin);
            }
          }

          .title {
            padding-top: 2px;
            color: currentColor;
            font-size: 13px;
            position: relative;
            font-weight: normal;
            @include media-breakpoint-up(sm) {
              font-size: 14px;
            }

            &:after {
              content: '';
              display: block;
              width: 13px;
              height: 13px;
              border-radius: 999px;
              border: 3px solid #aaa;
              background-color: var(--bg0);
              position: absolute;
              top: 1em;
              right: 100%;
              transform: translate(-7px, -50%);
            }
          }
          .link {
            text-decoration: none;
            color: var(--text2);
            border: 1px solid var(--dhusar-grey);
            border-radius: 2px;
            white-space: nowrap;
            padding: 4px 15px 5px;
            font-size: 13px;
            font-weight: normal;
          }
          &.done .progress-label {
            left: 100%;
            top: 50%;
            transform: translateY(-50%);
            color: var(--dhusar-grey);
            border-radius: 999px;
            border: solid 1px var(--super-silver);
            box-shadow: none;
            margin-left: 14px;
            display: block;
            @include media-breakpoint-down(sm) {
              padding-left: 12px;
              padding-right: 12px;
            }
          }
          &.current .progress-label {
            right: 0;
            top: 0;
            display: block;
            transform: translate(50%, calc(-100% - var(--arrowH)));
            font-size: 14px;
          }
          .progress {
            height: 4px;
            max-width: 600px;
            width: calc(100% - 91.4px);
            overflow: visible;
            position: relative;
            order: 99;
            margin-top: 24px;
            margin-bottom: 24px;

            @include media-breakpoint-up(md) {
              width: 100%;
              margin: 0;
              position: absolute;
              top: 50%;
              right: 200px;
              transform: translateY(-50%);
              max-width: 150px;
            }

            @include media-breakpoint-up(lg) {
              max-width: 400px;
            }

            &:after {
              content: '';
              display: block;
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              background-color: var(--zhen-zhu-bai-pearl);
              border-radius: 999px;
              z-index: -1;
            }
            &-bar {
              background: var(--bar);
              border-radius: 999px;
              position: relative;
              overflow: visible;
            }
            &-label {
              color: var(--label);
              position: absolute;
              box-shadow: 3px 3px 9px 0 rgba(0, 0, 0, 0.16);
              background-color: var(--bg0);
              border-radius: 4px;
              display: none;
              padding: 1px 7px 1px;
              --arrowH: 5px;
              --arrowW: 4px;
              @include media-breakpoint-up(sm) {
                --arrowH: 8px;
                --arrowW: 5px;
                padding: 6px 16px 5px;
              }

              &:after {
                width: 0;
                height: 0;
                content: ' ';
                position: absolute;
                top: 100%;
                left: 50%;
                transform: translateX(-50%);
                border-left: var(--arrowW) solid transparent;
                border-right: var(--arrowW) solid transparent;
                border-top: var(--arrowH) solid var(--bg0);
              }
            }
          }
        }
      }
    }
    &-info {
      padding-bottom: 28px;
      border-bottom: 1px solid var(--black-out);
      display: flex;
      align-items: center;
      @include gap(16px);
      @include media-breakpoint-up(sm) {
        @include gap(20px);
        flex-direction: column;
      }
      @include media-breakpoint-up(lg) {
        flex-direction: row;
      }

      &-thumbnail {
        aspect-ratio: 16/9;
        position: relative;
        line-height: 0;
        font-size: 0;
        min-width: 132px;
        overflow: hidden;
        min-height: 140px;

        @include media-breakpoint-up(sm) {
          width: 100%;
        }
        @include media-breakpoint-up(lg) {
          width: auto;
        }

        img {
          position: absolute;
          top: 50%;
          left: 50%;
          object-fit: cover;
          width: 100%;
          height: 100%;
          transform: translate(-50%, -50%);
        }
      }

      &-content {
        width: 100%;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        justify-content: space-between;
        @include gap(17px);
        @include media-breakpoint-up(sm) {
          @include gap(20px);
        }

        .btn {
          font-size: 18px;
          @include media-breakpoint-down(sm) {
            background-color: transparent;
            color: var(--blue-ruin);
            @include textDecoration;
            font-size: 16px;
            padding: 0;
            padding-bottom: 2px;
          }
        }
      }
      &-title {
        font-size: 20px;
        color: var(--text1);
        font-weight: bold;
        margin-bottom: 0;
        margin-top: 0;
        @include media-breakpoint-up(md) {
          font-size: 2.5rem;
          margin-bottom: 0;
        }
        @include media-breakpoint-up(lg) {
          @include textDecoration(var(--bg0), currentColor);
        }
      }
    }
  }
  // active
  .accordion-button:not(.collapsed) .accordion-header {
    &-description {
      display: block;
      max-width: 425px;
    }
  }
}

@mixin modal {
  .accordion {
    &-info {
      flex-wrap: nowrap;
      @include gap(20px, x);
      width: 100%;
      justify-content: flex-start;
      &-title {
        font-size: 28px;
        text-shadow: none;
        box-shadow: none;
        margin: 0;
      }
      &-description {
        font-size: 18px;
        color: var(--black-out, #000000);
      }
      &-right {
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
      }
    }
    &-button:after {
      width: 24px;
      height: 24px;
    }
    &-body-list-item.current {
      font-weight: normal;
    }
  }
}

@mixin CoverMenu {
  @include style1;
  .accordion {
    &-body {
      &-list {
        &-item {
          border-bottom: 1px solid var(--super-silver);
        }
      }
    }
    &-item {
      border-bottom: 1px solid var(--super-silver);
    }
  }
}
