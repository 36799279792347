html {
  font-size: 14px;
}

body {
  font-family: 'Noto Sans KR', sans-serif;
  line-height: 1.6;
  color: var(--text3);
}

span.primary {
  color: var(--blue-ruin);
}
