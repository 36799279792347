.bar {
  @import './pageheader';
  @import './class_cat';
  @import './class_single';
  @import './top';
  @import './breadcrumbs';
  @import './account';
  @import './page';

  &-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
  }
  &-main {
    position: absolute;
    width: 100%;
    top: 100%;
    background-color: var(--bg0);
    z-index: 900;
  }
  &-bg {
    position: absolute;
    object-fit: cover;
    width: 100%;
    height: 100%;
    top: 50%;
    z-index: -1;
    left: 0;
    transform: translateY(-50%);
    @include media-breakpoint-up(sm) {
      transform: translate(-50%, -50%);
      left: 50%;
    }
  }
}
