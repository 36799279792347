& {
  &-header {
    font-size: 18px;
    margin-bottom: 18px;
    @include media-breakpoint-down(lg) {
      color: var(--text1);
    }
    @include media-breakpoint-up(lg) {
      margin-bottom: 32px;
      text-align: center;
      font-size: 36px;
    }
  }
  &-table {
    --bs-gutter-x: 40px;
    margin-bottom: 8px;
    position: relative;
    overflow-x: hidden;

    @include media-breakpoint-up(sm) {
      --bs-gutter-x: 80px;
      border-top: 1px solid var(--black-out);
      border-bottom: 1px solid var(--black-out);
      margin-bottom: 52px;
    }

    > [class*='col'] {
      padding-top: 37px;
      padding-bottom: 37px;
      @include media-breakpoint-up(sm) {
        padding-top: 58px;
        padding-bottom: 58px;
      }
      position: relative;
      &:first-child {
        background-color: var(--zhen-zhu-bai-pearl);
        @include media-breakpoint-down(lg) {
          border-top: 1px solid var(--super-silver);
          .card-profile-label {
            align-self: flex-start;
            font-size: 14px;
            font-weight: normal;
            margin-bottom: 10px;
          }
        }
        @include media-breakpoint-up(lg) {
          background-color: transparent;
          padding-top: 60px;
          padding-bottom: 60px;
        }
        &:after {
          content: '';
          display: block;
          position: absolute;
          background-color: var(--steam);
          bottom: 0;
          left: 0;
          width: 100%;
          height: 1px;
          @include media-breakpoint-up(lg) {
            width: 1px;
            height: 100%;
            right: 0;
            top: 0;
            left: auto;
            transform: translateX(50%);
          }
        }
      }
    }
    &-footer {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      font-size: 20px;
      @include media-breakpoint-down(sm) {
        button,
        a {
          width: 100%;
        }
      }
      a {
        text-decoration: underline;
        font-size: 20px;
        color: var(--text3);
      }
    }
  }
  .input-rootWrap {
    max-width: 620px;
    margin: auto;
  }
  .input-item {
    color: var(--text2);
    &[readonly] {
      padding-left: 0;
    }
  }
  .input-label {
    color: var(--text2);
    &:not(:last-child) {
      margin-bottom: 24px;
      @include media-breakpoint-up(lg) {
        margin-bottom: 32px;
      }
    }
    span {
      font-size: 14px;
      @include media-breakpoint-up(lg) {
        font-size: 18px;
        font-weight: bold;
      }
    }
    [required]:not([readonly]) + :after {
      content: '*';
      margin-left: 8px;
      color: var(--blue-ruin);
      font-size: 1.2em;
      vertical-align: text-top;
    }
  }
  .card-profile-btn {
    display: flex;
    @include gap(8px);
    a {
      width: 100%;
      padding-top: 11px;
      padding-bottom: 10px;
      background-color: var(--invert-text0);
    }
  }
}
