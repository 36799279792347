@import './autoload/_bootstrap.scss'; @import './autoload/_headroom.scss'; @import './autoload/_swiper.scss';

@import './functions/_gap.scss'; @import './functions/_rgb.scss'; @import './functions/_textDecoration.scss'; @import './functions/_ui.scss';

@import './common/reset';
@import './common/root-zeplin';
@import './common/root';
@import './common/global';
@import './common/token';

@import './layouts/main';

@import './components/modal';
@import './components/icon';
@import './components/btn';
@import './components/bar';
@import './components/GNB';
@import './components/FNB';
@import './components/rating';
@import './components/card';
@import './components/tab';
@import './components/progress';
@import './components/input';
@import './components/shop';
@import './components/list';

@import './pages/home';
@import './pages/page-class';
@import './pages/account';
@import './pages/search';
@import './pages/signup';
@import './pages/roadmap';

*, ::before, ::after{

    --tw-border-spacing-x: 0;

    --tw-border-spacing-y: 0;

    --tw-translate-x: 0;

    --tw-translate-y: 0;

    --tw-rotate: 0;

    --tw-skew-x: 0;

    --tw-skew-y: 0;

    --tw-scale-x: 1;

    --tw-scale-y: 1;

    --tw-pan-x:  ;

    --tw-pan-y:  ;

    --tw-pinch-zoom:  ;

    --tw-scroll-snap-strictness: proximity;

    --tw-ordinal:  ;

    --tw-slashed-zero:  ;

    --tw-numeric-figure:  ;

    --tw-numeric-spacing:  ;

    --tw-numeric-fraction:  ;

    --tw-ring-inset:  ;

    --tw-ring-offset-width: 0px;

    --tw-ring-offset-color: #fff;

    --tw-ring-color: rgb(59 130 246 / 0.5);

    --tw-ring-offset-shadow: 0 0 #0000;

    --tw-ring-shadow: 0 0 #0000;

    --tw-shadow: 0 0 #0000;

    --tw-shadow-colored: 0 0 #0000;

    --tw-blur:  ;

    --tw-brightness:  ;

    --tw-contrast:  ;

    --tw-grayscale:  ;

    --tw-hue-rotate:  ;

    --tw-invert:  ;

    --tw-saturate:  ;

    --tw-sepia:  ;

    --tw-drop-shadow:  ;

    --tw-backdrop-blur:  ;

    --tw-backdrop-brightness:  ;

    --tw-backdrop-contrast:  ;

    --tw-backdrop-grayscale:  ;

    --tw-backdrop-hue-rotate:  ;

    --tw-backdrop-invert:  ;

    --tw-backdrop-opacity:  ;

    --tw-backdrop-saturate:  ;

    --tw-backdrop-sepia:  
}

::-webkit-backdrop{

    --tw-border-spacing-x: 0;

    --tw-border-spacing-y: 0;

    --tw-translate-x: 0;

    --tw-translate-y: 0;

    --tw-rotate: 0;

    --tw-skew-x: 0;

    --tw-skew-y: 0;

    --tw-scale-x: 1;

    --tw-scale-y: 1;

    --tw-pan-x:  ;

    --tw-pan-y:  ;

    --tw-pinch-zoom:  ;

    --tw-scroll-snap-strictness: proximity;

    --tw-ordinal:  ;

    --tw-slashed-zero:  ;

    --tw-numeric-figure:  ;

    --tw-numeric-spacing:  ;

    --tw-numeric-fraction:  ;

    --tw-ring-inset:  ;

    --tw-ring-offset-width: 0px;

    --tw-ring-offset-color: #fff;

    --tw-ring-color: rgb(59 130 246 / 0.5);

    --tw-ring-offset-shadow: 0 0 #0000;

    --tw-ring-shadow: 0 0 #0000;

    --tw-shadow: 0 0 #0000;

    --tw-shadow-colored: 0 0 #0000;

    --tw-blur:  ;

    --tw-brightness:  ;

    --tw-contrast:  ;

    --tw-grayscale:  ;

    --tw-hue-rotate:  ;

    --tw-invert:  ;

    --tw-saturate:  ;

    --tw-sepia:  ;

    --tw-drop-shadow:  ;

    --tw-backdrop-blur:  ;

    --tw-backdrop-brightness:  ;

    --tw-backdrop-contrast:  ;

    --tw-backdrop-grayscale:  ;

    --tw-backdrop-hue-rotate:  ;

    --tw-backdrop-invert:  ;

    --tw-backdrop-opacity:  ;

    --tw-backdrop-saturate:  ;

    --tw-backdrop-sepia:  
}

::backdrop{

    --tw-border-spacing-x: 0;

    --tw-border-spacing-y: 0;

    --tw-translate-x: 0;

    --tw-translate-y: 0;

    --tw-rotate: 0;

    --tw-skew-x: 0;

    --tw-skew-y: 0;

    --tw-scale-x: 1;

    --tw-scale-y: 1;

    --tw-pan-x:  ;

    --tw-pan-y:  ;

    --tw-pinch-zoom:  ;

    --tw-scroll-snap-strictness: proximity;

    --tw-ordinal:  ;

    --tw-slashed-zero:  ;

    --tw-numeric-figure:  ;

    --tw-numeric-spacing:  ;

    --tw-numeric-fraction:  ;

    --tw-ring-inset:  ;

    --tw-ring-offset-width: 0px;

    --tw-ring-offset-color: #fff;

    --tw-ring-color: rgb(59 130 246 / 0.5);

    --tw-ring-offset-shadow: 0 0 #0000;

    --tw-ring-shadow: 0 0 #0000;

    --tw-shadow: 0 0 #0000;

    --tw-shadow-colored: 0 0 #0000;

    --tw-blur:  ;

    --tw-brightness:  ;

    --tw-contrast:  ;

    --tw-grayscale:  ;

    --tw-hue-rotate:  ;

    --tw-invert:  ;

    --tw-saturate:  ;

    --tw-sepia:  ;

    --tw-drop-shadow:  ;

    --tw-backdrop-blur:  ;

    --tw-backdrop-brightness:  ;

    --tw-backdrop-contrast:  ;

    --tw-backdrop-grayscale:  ;

    --tw-backdrop-hue-rotate:  ;

    --tw-backdrop-invert:  ;

    --tw-backdrop-opacity:  ;

    --tw-backdrop-saturate:  ;

    --tw-backdrop-sepia:  
}
.container{

    width: 100%
}
@media (min-width: 640px){

    .container{

        max-width: 640px
    }
}
@media (min-width: 768px){

    .container{

        max-width: 768px
    }
}
@media (min-width: 1024px){

    .container{

        max-width: 1024px
    }
}
@media (min-width: 1280px){

    .container{

        max-width: 1280px
    }
}
@media (min-width: 1536px){

    .container{

        max-width: 1536px
    }
}
.visible{

    visibility: visible
}
.static{

    position: static
}
.fixed{

    position: fixed
}
.absolute{

    position: absolute
}
.relative{

    position: relative
}
.col-auto{

    grid-column: auto
}
.m-0{

    margin: 0px
}
.mb-3{

    margin-bottom: 0.75rem
}
.mb-2{

    margin-bottom: 0.5rem
}
.mt-1{

    margin-top: 0.25rem
}
.mt-4{

    margin-top: 1rem
}
.block{

    display: block
}
.inline-block{

    display: inline-block
}
.inline{

    display: inline
}
.flex{

    display: flex
}
.table{

    display: table
}
.grid{

    display: grid
}
.list-item{

    display: list-item
}
.hidden{

    display: none
}
.border-collapse{

    border-collapse: collapse
}
.transform{

    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}
.flex-wrap{

    flex-wrap: wrap
}
.items-start{

    align-items: flex-start
}
.border{

    border-width: 1px
}
.p-0{

    padding: 0px
}
.px-3{

    padding-left: 0.75rem;

    padding-right: 0.75rem
}
.py-4{

    padding-top: 1rem;

    padding-bottom: 1rem
}
.px-4{

    padding-left: 1rem;

    padding-right: 1rem
}
.py-5{

    padding-top: 1.25rem;

    padding-bottom: 1.25rem
}
.py-3{

    padding-top: 0.75rem;

    padding-bottom: 0.75rem
}
.px-0{

    padding-left: 0px;

    padding-right: 0px
}
.pb-1{

    padding-bottom: 0.25rem
}
.pb-0{

    padding-bottom: 0px
}
.pt-0{

    padding-top: 0px
}
.pb-4{

    padding-bottom: 1rem
}
.pb-2{

    padding-bottom: 0.5rem
}
.pt-2{

    padding-top: 0.5rem
}
.text-center{

    text-align: center
}
.italic{

    font-style: italic
}
.underline{

    -webkit-text-decoration-line: underline;

            text-decoration-line: underline
}
.outline{

    outline-style: solid
}
.blur{

    --tw-blur: blur(8px);

    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow)
}
.filter{

    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow)
}
.transition{

    transition-property: color, background-color, border-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-text-decoration-color, -webkit-backdrop-filter;

    transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;

    transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-text-decoration-color, -webkit-backdrop-filter;

    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);

    transition-duration: 150ms
}
.ease-in-out{

    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1)
}
