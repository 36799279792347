.home {
  $gp: &;

  @include media-breakpoint-down(sm) {
    main {
      margin-top: var(--barTopHeight);
    }
    .gnb-pageheader {
      display: none;
    }
  }

  @mixin title {
    font-weight: 300;
    font-size: 18px;
    @include media-breakpoint-up(sm) {
      font-size: 40px;
    }
    strong,
    b {
      font-size: 32px;
      @include media-breakpoint-up(sm) {
        font-size: 48px;
      }
    }
  }

  // 섹션 공용
  .section {
    margin-bottom: 25px;

    @include media-breakpoint-up(sm) {
      margin-bottom: 60px;
    }
    @include media-breakpoint-up(lg) {
      margin-bottom: 120px;
    }
  }
  // section
  .hero {
    @import './hero-section';
  }
  .s1 {
    @import './section1';
  }
  .s2 {
    @import './section2';
  }
  .s3 {
    @import './section3';
  }
}
