@at-root {
  .page-classSingle {
    --barPageheaderHight: 160px;
    @include media-breakpoint-up(sm) {
      --barPageheaderHight: 120px;
    }
    @include media-breakpoint-up(lg) {
      --barPageheaderHight: 200px;
    }
  }
}

&-classSingle {
  position: relative;
  color: var(--invert-text0);

  &-container {
    display: flex;
    justify-content: space-between;
    height: 100%;
    @include media-breakpoint-down(sm) {
      padding-right: 0;
    }
    @include media-breakpoint-up(lg) {
      padding-top: 20px;
      padding-bottom: 20px;
    }

    &-left {
      display: flex;
      justify-content: center;
      flex-direction: column;
      [class*='breadcrumbs'] {
        color: var(--paris);
        font-size: 12px;
        @include media-breakpoint-up(sm) {
          font-size: 20px;
        }
      }
    }
    &-right {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      color: rgba(var(--RGB-invert-text0), 0.8);
      text-decoration: none;
      padding: 20px;
      background-color: var(--black-out);
      @include media-breakpoint-down(sm) {
        @include gap(14px, y);
      }
      @include media-breakpoint-up(sm) {
        padding: 0;
        align-items: flex-end;
        &:after {
          content: '';
          display: block;
          width: 42vw;
          height: 100%;
          background-color: var(--black-out);
          position: absolute;
          right: 0;
          top: 0;
        }
      }
      > * {
        z-index: 2;
      }
    }
  }

  &-title {
    margin-bottom: 0;
    color: currentColor;
    font-size: 24px;
    margin-top: 4px;
    @include media-breakpoint-up(lg) {
      margin-top: 14px;
      font-size: 3.5rem;
    }
  }
  &-next {
    font-size: 12px;
    color: var(--cerebral-grey);
    opacity: 0.75;
    @include media-breakpoint-up(sm) {
      font-size: 16px;
    }
  }

  .arrow {
    width: 24px;
    height: 24px;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='28' height='28' viewBox='0 0 28 28'%3E%3Cg%3E%3Cpath fill='%23ccc' d='M8.642 12.916L.473 3.266A2.055 2.055 0 0 1 .595.508a1.731 1.731 0 0 1 2.568.131L12.444 11.6a2.06 2.06 0 0 1 0 2.626L3.163 25.194a1.731 1.731 0 0 1-2.568.131 2.055 2.055 0 0 1-.122-2.758z' transform='translate(28) rotate(90) translate(7.083 1.167)'/%3E%3C/g%3E%3C/svg%3E%0A");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    transform: rotate(-90deg);
  }
}

@mixin bar-class-single-small {
  & + main.class.single {
    --barPageheaderHight: 56px;
  }
  .bar-classSingle {
    --barPageheaderHight: 56px;
    background-color: var(--theatre-blue);

    &-container {
      @include media-breakpoint-up(sm) {
        padding-top: 14px;
        padding-bottom: 14px;
      }
      &-right {
        flex-direction: row;
        gap: 12px;
        align-items: center;
      }
    }
    &-title {
      margin: 0;
      font-size: 24px;
      @include media-breakpoint-down(sm) {
        padding-top: 14px;
        padding-bottom: 14px;
      }
    }
    &-next {
      @include media-breakpoint-down(sm) {
        font-size: 16px;
        padding-bottom: 0.24rem;
      }
    }
    .arrow {
      width: 18px;
      height: 18px;
    }
    &-info {
      display: none;
    }
    &-btn {
      font-size: 18px;
      &.review {
        display: block;
      }
    }
  }
}
